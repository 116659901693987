(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name settings.events.controller:EventsEditCtrl
   *
   * @description
   *
   */
  angular
    .module('settings.events')
    .controller('EventsEditCtrl', EventsEditCtrl);

  function EventsEditCtrl($state, $stateParams, $filter, Restangular) {
    var vm = this;
    vm.event = {};
    vm.isEditing = $state.is('settings.events.edit');
    vm.errors = [];
    vm.submit = submit;
    vm.starts_at = new Date();
    vm.ends_at = new Date();
    vm.upperCaseCode = upperCaseCode;

    // Load event
    if (vm.isEditing) {
      Restangular.one('events', $stateParams.eventId).get().then(function (response) {
        vm.event = response.data;
        vm.starts_at = moment(vm.event.starts_at).toDate();
        vm.ends_at = moment(vm.event.ends_at).toDate();
        vm.event.min_donation = $filter('dollars')(vm.event.min_donation);
        vm.event.entry_fee = $filter('dollars')(vm.event.entry_fee);
        vm.event.meals = $filter('dollars')(vm.event.meals);
        vm.event.dinners = $filter('dollars')(vm.event.dinners);
        vm.event.option = $filter('dollars')(vm.event.option);
      });
    } else {
        vm.event.parts = 1;
    }

    // Save event
    function submit() {

      var data = angular.copy(vm.event);

      data.parts = parseInt(vm.event.parts, 10)

      // Convert dates
      data.starts_at = vm.starts_at;
      data.ends_at = vm.ends_at;

      // Convert dollars to cents
      data.min_donation = $filter('cents')(vm.event.min_donation);
      data.entry_fee = $filter('cents')(vm.event.entry_fee);
      data.meals = $filter('cents')(vm.event.meals);
      data.dinners = $filter('cents')(vm.event.dinners);
      data.option = $filter('cents')(vm.event.option);

      if (vm.isEditing) {
        Restangular.all('events').customPUT(data, vm.event.id).then(submitSuccess, submitFailure);
      } else {
        Restangular.all('events').post(data).then(submitSuccess, submitFailure);
      }
    }

    function submitSuccess(response) {
      $state.go('settings.events.list');
    }

    function submitFailure(response) {
      console.log('event submit failed', response.data.errors);
      vm.errors = response.data.errors;
    }

    function upperCaseCode() {
      if (vm.event.code) {
        vm.event.code = vm.event.code.toUpperCase();
      }
    }
  }
}());
